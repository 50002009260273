// Import jQuery 
//////////////////////////////////////////////////////////////////////
window.$ = window.jQuery = jQuery;

// Import Libraries
//////////////////////////////////////////////////////////////////////

// Utils
//////////////////////////////////////////////////////////////////////
import * as utils from './utils/utils.js'

// Require modules
//////////////////////////////////////////////////////////////////////
import cssVarsDynamic from './modules/css-vars-dynamic.js'
import browserClassHelpers from './modules/browser-class-helpers.js'
import header from './modules/header.js'
import footer from './modules/footer.js'
import register from './modules/register.js'
import navSecondary from './modules/nav-secondary.js'
import thematics from './modules/thematics.js'
import customFileInput from './modules/customFileInput.js'

// Components
//////////////////////////////////////////////////////////////////////
import events from './components/b14_events.js'
import b08_slider from './components/b08_slider.js'
import b13gridSlider from './components/b13gridSlider.js'

// Init all
//////////////////////////////////////////////////////////////////////
$(function(){

	$("html").removeClass("no-js");

	browserClassHelpers.init();
	
	window.onload = function(){
		cssVarsDynamic.init();
	}
	
	header.init();
	footer.init();

	// Nav secondary
	if (utils.exist('.navSecondary')) {
		navSecondary.init();
	}

	// Events
	if (utils.exist('.js-events-calendar')) {
		events.init();
	}

	// Join
	if (utils.exist('.js-b13gridSlider')) {
		b13gridSlider.init();
	}

	// Join
	if (utils.exist('.js-b08_slider')) {
		b08_slider.init();
	}

	// Register
	if (utils.exist('.js-register-tpl')) {
		register.init();
	}

	if(utils.exist('.js-daterange')){
		$('.js-daterange').each(function(e){
			$(this).daterangepicker({
				autoUpdateInput: false,
				locale: window.locale
			});
			$(this).on('apply.daterangepicker', function(ev, picker) {
				$(this).val(picker.startDate.format(locale.format) + ' - ' + picker.endDate.format(locale.format));
			});
		});
	}
	if(utils.exist('.js-daterange-monthYear')){
		$('.js-daterange-monthYear').each(function(e){
			let $this = $(this);
			let initVal = $this.val();
			$this.daterangepicker({
				showDropdowns: true,
				singleDatePicker: true,
				autoUpdateInput: true,
				locale: {
					format: "MMMM YYYY",
					cancelLabel: 'Clear',
					monthNames: [
						"January",
						"February",
						"March",
						"April",
						"May",
						"June",
						"July",
						"August",
						"September",
						"October",
						"November",
						"December"
					],
				}
			});

	      	$this.on('show.daterangepicker', function(ev, picker) {
	      		picker.container.addClass('monthly');
	      	});

	      	$this.on('hide.daterangepicker', function(ev, picker) {
				/*
				 * i selected the third row because there was month
				 * that date 1 on second row, so i feel to keep it save
				 * with choosing the third row
				 */
				var td = $(picker.container).find('.table-condensed tbody tr:nth-child(3) td:first-child');
				/*
				 * the setTimeout have on purpose to delay calling trigger
				 * event when choosing date on third row, if you not provide
				 * the timeout, it will throw error maximum callstack
				 */
				setTimeout(function() {
					/*
					 * on the newer version to pick some date was changed into event
					 * mousedown
					 */
					td.trigger('mousedown');
					/*
					 * this was optional, because in my case i need send date with
					 * starting day with 1 to keep backend neat
					 */
					picker.setStartDate(picker.startDate.date(1));
					picker.setEndDate(picker.endDate.date(1));
				}, 1);
			})

			// $(this).on('apply.daterangepicker', function(ev, picker) {
			// 	$(this).val(picker.startDate.format(locale.format) + ' - ' + picker.endDate.format(locale.format));
			// });

			// sinon initialise avec le mois actuel
			if(initVal == ''){
				$this.val('');
			}
			$this.on('cancel.daterangepicker', function(ev, picker) {
				setTimeout(function() {
					$this.val('');
				}, 10);
			});
		});
	}

	// Thematics
	if (utils.exist('.js-thematics')) {
		thematics.init();
	}

	$(".js-submitOnChange").on('change',function(){
		let $this = $(this);
		let $form = $this.closest('form');
		if(!$form.length){
			$form = $("#"+$this.attr('form'));
			if(!$form.length){
				return;
			}
		}
		$form.submit();
	});

	if (utils.exist('.js-customFileInput')) {
		customFileInput.init()
	}

	$(".js-sidebarMobile-section-fixed").each(function(){
		var $this = $(this);
		var $sidebar__content = $this.find(".js-sidebarMobile-section-content");
		$this.find(".js-section__title").on('click', function(){
		console.log($(this));
			$(this).toggleClass('--open');
			$sidebar__content.slideToggle();
		});
	})
});
