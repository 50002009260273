// JS Script
import Swiper from 'swiper';
import { Navigation, Pagination, Scrollbar } from 'swiper';

const b08_slider = {
	ui: {},

	init: function() {
        const swiper = new Swiper('.js-b08_slider', {
          // Install modules
          modules: [Navigation, Pagination, Scrollbar],
          speed: 400,
          loop: false,
          navigation: {
            enabled: true,
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
        });
	},

};

module.exports = b08_slider;
