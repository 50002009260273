const footer = {
	ui: {},

	init: function() {
		this.initToggleBtns();
	},

    initToggleBtns: function() {

        const btns = $('.js-footer-nav-btn');

        btns.on('click', function(e){
            e.preventDefault();

            $(this).closest('.footerNav__item').find('.footerNav__drop').slideToggle()
            $(this).toggleClass('--active');
        })

    }

};

module.exports = footer;
