import Swiper from 'swiper';

const b13gridSlider = {
	ui: {},

	init: function() {
		this.initSlider();
	},

    initSlider: function() {
        let sliders = [];
        const sliderEl = document.querySelectorAll('.js-b13gridSlider');
        for (var i = 0; i < sliderEl.length; i++) {
            sliders.push(new Swiper(sliderEl[i], {
                slidesPerView: 1.1,
                spaceBetween: 16,
                breakpoints: {
                    767: {
                      slidesPerView: 2.2,
                      spaceBetween: 20
                    },
                }
            }));
        }
    }

};

module.exports = b13gridSlider;
