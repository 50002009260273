import Swiper from 'swiper';

const register = {
	ui: {},

	init: function() {
		this.initSliders();
        this.handleTabs();
	},

    initSliders: function() {

        const sliderElements = document.querySelectorAll('.js-tab-slider')

        if(!sliderElements.length) return;

        sliderElements.forEach(sliderEl => {

            const swiper = new Swiper(sliderEl, {
                slidesPerView: 1.2,
                spaceBetween: 16,
                breakpoints: {
                    767: {
                        slidesPerView: 2.2
                    },
                    992: {
                        slidesPerView: 3
                    }
                }
            })

        })

    },

    handleTabs: function() {

        const tabBtns = document.querySelectorAll('.js-tab-btn');

        if(!tabBtns.length) return;

        tabBtns.forEach(btn => {

            const tabTarget = btn.dataset.tab;
            
            btn.addEventListener('click', e => {

                const currentActiveBtn = btn.closest('.tabs__list').querySelector('.js-tab-btn.--active');
                currentActiveBtn.classList.remove('--active')
                btn.classList.add('--active');

                const currentActiveTab = document.querySelector('.js-tab-el.--active');
                currentActiveTab.classList.remove('--active')
                document.querySelector(`.js-tab-el[data-tab-item="${tabTarget}"]`).classList.add('--active');

            })

        })


    }

};

module.exports = register;
