import Swiper from 'swiper';

const thematics = {
	ui: {},

	init: function() {
		this.initSliders();
        this.handleToggle();
	},

    initSliders: function() {

        const sliderElement = document.querySelector('.js-thematics-slider')

        if(!sliderElement) return;

        const swiper = new Swiper(sliderElement, {
            slidesPerView: 1.2,
            spaceBetween: 16,
            breakpoints: {
                767: {
                    slidesPerView: 2.2
                },
                992: {
                    slidesPerView: 3.5
                }
            }
        })

    },

    handleToggle: function() {

        const btn = document.querySelector('.js-browse-btn');

        if(!btn) return;

        btn.addEventListener('click', function(e){

            e.preventDefault();

            if (btn.classList.contains('--active')) {
                $('.js-thematics-list').slideUp()
                btn.classList.remove('--active')
            } else {
                $('.js-thematics-list').slideDown()
                btn.classList.add('--active')
            }
        })


    }

};

module.exports = thematics;
