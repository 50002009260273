import gsap from 'gsap';
import {disableScroll, enableScroll} from '../utils/utils.js'

const header = {
	ui: {},

	init: function() {
		this.initMenuAnimations();
		this.initMenuMobile();
		this.handleLanguageSwitcher();
		this.handleSearchBar();
		this.handleUserMenu();
		this.getDynamicMenu();
		this.handleLogout();

		this.currentTimeline = null;

		// When dynamic header is loaded by ajax
		const body = document.querySelector('body')
		body.addEventListener('header:updated', () => {
			this.handleUserMenu()
			this.handleLogout();
		})
	},

	initMenuAnimations: function(){
		const menuBtns = document.querySelectorAll('.js-menu-btn');

		if(menuBtns){
			menuBtns.forEach(btn => {

				const submenu = btn.closest('.headerNav__item').querySelector('.headerNavDrop');

				if(submenu){
					const menuItems = submenu.querySelectorAll('.js-menu-item')
					const layer = submenu.querySelector('.headerNavDrop__layer');
					const timeline = gsap.timeline({ paused: true })

					timeline.set(submenu, {display: "block"}, 'start')
							.add(() => this.handleCSSVarsInMenu(submenu), 'start')
					        .from(layer, { scaleY: 0, duration: 0.3, ease: 'power3.out' }, "start")
							.from(menuItems, { autoAlpha: 0, y: 30, duration: 1, ease: 'power2.out', stagger: 0.1}, "-=0.3")

					btn.addEventListener('click', (e) => {
						e.preventDefault();

						if (btn.classList.contains('--active')) {
							timeline.timeScale(2).reverse();
							btn.classList.remove('--active');

							this.currentTimeline = null;
						} else {

							if (this.currentTimeline) {
								this.currentTimeline.timeScale(4).reverse();
								document.querySelector('.js-menu-btn.--active').classList.remove('--active');
							}
							
							timeline.timeScale(1).play();
							btn.classList.add('--active');

							this.currentTimeline = timeline;
							this.handleLanguageSwitcherClose();
							this.handleUserMenuClose();
						}
					})
				}

			})
		}

		document.addEventListener('closeMenu', (e) => {
			if (this.currentTimeline) {
				this.currentTimeline.timeScale(4).reverse();
				document.querySelector('.js-menu-btn.--active').classList.remove('--active');
				this.currentTimeline = null;
			}
		});
		document.addEventListener('click', (e) => {
			if (!e.target.closest('.header')) {
				document.dispatchEvent(
					new CustomEvent("closeMenu"),
				);
			}
		})

	},

	initMenuMobile: function(){

		this.initMenuMobileAnimation();
		this.initMenuMobileDropdowns();

	},

    handleSkewVarsDynamization: function(btn){

        if (!btn || !btn.length) return;
            
        skewHoverElements.forEach(item => {
            const itemHeight = item.offsetHeight;
            const itemWidth = item.offsetWidth;

            if(!item.classList.contains('--varSet')){
                item.style.setProperty('--item-height', itemHeight + "px");
                item.style.setProperty('--item-width', itemWidth + "px");
            }
        })

	},
	initMenuMobileAnimation: function(){

		window.gsap = gsap;
		const burgerBtn = document.querySelector('.js-burger-btn');
		const closeBtns = document.querySelectorAll('.js-mobileNav-close');
		const timeline = gsap.timeline({ paused: true });
		const timelineOverlay = gsap.timeline({ paused: true });

		if (!burgerBtn) return;

		timelineOverlay.set('.js-mobileNav__overlay', { display: 'block' }, "start")
			    	   .from('.js-mobileNav__overlay', { opacity: 0, duration: 1, ease: "power2.out" }, "start")
			    	   .to('.js-mobileNav__overlay', { opacity: 1, duration: 1, ease: "power2.out" }, "start")

		timeline.set('.js-mobilenav-animation-el', { display: "flex" }, "start")
			    .from('.js-mobilenav-animation-el', { x: "-100%", duration: 0.4, ease: "power2.out" }, "start")
				.from('.js-mobilenav-animation-item', { y: 40, autoAlpha: 0, duration: 1, stagger: 0.1, ease: "power4.out"}, "-=0.3");

		burgerBtn.addEventListener('click', (e)=>{

			e.preventDefault();
			timeline.timeScale(1).play();
			timelineOverlay.timeScale(1).play();
			disableScroll();

			this.handleSkewVarsDynamization(document.querySelector('.js-mobilenav-animation-el .js-burger-btn'));
		})

		closeBtns.forEach(btn => {

			btn.addEventListener('click', function(e){
			
				e.preventDefault();
				timeline.timeScale(2).reverse();
				timelineOverlay.timeScale(2).reverse();
				enableScroll();
	
			})

		})

	},

	initMenuMobileDropdowns: function(){

		const btns = $('.js-mobilenav-btn')

		if (!btns.length) return;

		btns.on('click', function(e){
			e.preventDefault();
			
			const dropdown = $(this).closest('li').find('.js-mobilenav-drop:first')

			dropdown.slideToggle();
			$(this).toggleClass('--active')
		})

	},

	handleCSSVarsInMenu: function(container){

		const skewHoverElements = container.querySelectorAll('.js-skew-hover');

		if (!skewHoverElements.length) return;

		skewHoverElements.forEach(item => {
            const itemHeight = item.offsetHeight;
            const itemWidth = item.offsetWidth;
            if(!item.classList.contains('--varSet')){
	            item.style.setProperty('--item-height', itemHeight + "px");
	            item.style.setProperty('--item-width', itemWidth + "px");
            }
        })
	},

	handleLanguageSwitcherClose: function(){
		const langSwitcherbtn = document.querySelector('.js-langswitcher-btn');

		langSwitcherbtn.dispatchEvent(
			new CustomEvent("close"),
		);
	},
	handleLanguageSwitcher: function(){

		const langSwitcherbtn = document.querySelector('.js-langswitcher-btn');

		if (!langSwitcherbtn) return;

		const timeline = gsap.timeline({ paused: true })

		timeline.set('.js-langswitcher-dropdown', { display: "block" }, "start")
				.from('.js-langswitcher-dropdown', { scaleY: 0, duration: 0.3, ease: 'power4.out' }, "start")
				.from('.js-langswitcher-item', { autoAlpha: 0, y: 10, duration: 0.5, stagger: 0.1, ease: 'power4.out' }, "-=0.3")


		langSwitcherbtn.addEventListener('open', (e) => {
			timeline.timeScale(1).play()
			langSwitcherbtn.classList.add('--active')
			document.dispatchEvent(
				new CustomEvent("closeMenu"),
			);
		})
		langSwitcherbtn.addEventListener('close', (e) => {
			timeline.timeScale(2).reverse()
			langSwitcherbtn.classList.remove('--active')
		})

		langSwitcherbtn.addEventListener('click', (e) => {

			e.preventDefault()

			if (langSwitcherbtn.classList.contains('--active')) {
				langSwitcherbtn.dispatchEvent(
					new CustomEvent("close"),
				);
			} else {
				langSwitcherbtn.dispatchEvent(
					new CustomEvent("open"),
				);
			}

		})

	},

	handleSearchBar: function(){

		const openSearchBtn = document.querySelector('.js-open-search');
		const searchBar = document.querySelector('.js-search-bar');
		const inputField = document.querySelector('.js-search-field');
		const closeSearchBtn = document.querySelector('.js-close-search')
		
		if (!openSearchBtn || !searchBar) return;

		const timeline = gsap.timeline({ paused: true, onComplete: () => inputField.focus() })

		timeline.to('.js-header-content', { y: -20, autoAlpha: 0, duration: 0.3, ease: 'power2.out' }, 'start')
				.set(searchBar, { display: "flex" }, 'start+=0.1')
				.from(searchBar, { x: "100%", duration: 0.5, ease: "power2.out" }, 'start+=0.1')
				.add(() => this.handleCSSVarsInMenu(searchBar), 'start+=0.1')

		openSearchBtn.addEventListener('click', (e) => {
			e.preventDefault();
			timeline.timeScale(1).play();
		})

		if (closeSearchBtn) {
			closeSearchBtn.addEventListener('click', (e) => {
				e.preventDefault();
				timeline.timeScale(2).reverse();
			})
		}

	},

	handleUserMenuClose: function(){
		const userMenuBtn = document.querySelector('.js_user_menu_button');

		userMenuBtn.dispatchEvent(
			new CustomEvent("close"),
		);
	},
	handleUserMenu: function(){

		const userMenuBtn = document.querySelector('.js_user_menu_button');

		if(!userMenuBtn) return;

		const timeline = gsap.timeline({ paused: true })

		timeline.set('.js_user_menu', { display: "flex" }, "start")
				.from('.js_user_menu_layer', { scaleY: 0, duration: 0.5, ease: 'power4.out' }, "start")
				.from('.js_user_menu_item', { autoAlpha: 0, y: 10, duration: 0.5, stagger: 0.1, ease: 'power4.out' }, "-=0.5")

		userMenuBtn.addEventListener('open', (e) => {
			timeline.timeScale(1).play()
			userMenuBtn.classList.add('--active')
			this.handleLanguageSwitcherClose();
			this.handleSkewVarsDynamization(document.querySelector('.js_user_menu .js-burger-btn'));

			document.dispatchEvent(
				new CustomEvent("closeMenu"),
			);
		})

		userMenuBtn.addEventListener('close', (e) => {
			timeline.timeScale(2).reverse()
			userMenuBtn.classList.remove('--active')
		})

		userMenuBtn.addEventListener('click', (e) => {

			e.preventDefault()

			if (userMenuBtn.classList.contains('--active')) {
				userMenuBtn.dispatchEvent(
					new CustomEvent("close"),
				);
			} else {
				userMenuBtn.dispatchEvent(
					new CustomEvent("open"),
				);
			}

		})

	},

	getDynamicMenu: function(){

		const container = document.querySelector('.js-header-dynamic-login');
		const body = document.querySelector('body')

        $.ajax({
            url: wordpress_vars.ajax_url,
            type: 'POST',
            data: {
                action: 'get_dynamic_header',
				current_url: window.location.href
            },
            beforeSend: function(){
                container.classList.add('--loading')
            },
            success: function(response) {
                container.innerHTML = response;
				body.dispatchEvent(
					new CustomEvent("header:updated"),
				);
            },
            complete: function(){
                container.classList.remove('--loading')
            },
            error: function(xhr, status, error) {
                console.error('AJAX Error:', status, error);
            }
        });

	},

	handleLogout: function(){

		const logoutBtn = document.querySelector('.js-logout-btn');

		if (!logoutBtn) return;

		logoutBtn.addEventListener('click', e => {
			e.preventDefault()

			var data = {
				action: 'get_logout_code',
				current_url: window.location.href.split('?')[0]
			};
		
			jQuery.ajax({
				type: 'POST',
				url: wordpress_vars.ajax_url,
				data: data,
				success: function(response) {
					if (response.data) {
						const jsonDatas = JSON.parse(response.data);
						window.location.href = `${wordpress_vars.wildapricot_url}/sys/login/logout?nonce=${jsonDatas.nonce}`
					}else{
						window.location.href = window.location.href+"?state=logout";
					}
				},
				error: function(error) {
					console.error('Erreur Ajax:', error);
				}
			});

		})

	}

};

module.exports = header;
