const cssVarsDynamic = {
	ui: {},

	init: function() {
        this.handleRootVarsDynamization();
        this.handleSkewVarsDynamization();
        window.addEventListener('resize', ()=>{
            requestAnimationFrame(()=>{
                this.handleRootVarsDynamization();
                this.handleSkewVarsDynamization();
            })
        });
	},

	handleRootVarsDynamization: function(){

		let root = document.documentElement;
        const header = document.querySelector('.header')

        root.style.setProperty('--viewport-height', window.innerHeight + "px");

        if (header) {
            root.style.setProperty('--header-height', header.clientHeight + "px"); 
        }

	},

    handleSkewVarsDynamization: function(){

		const skewHoverElements = document.querySelectorAll('.js-skew-hover');

        if (!skewHoverElements.length) return;
            
        skewHoverElements.forEach(item => {
            const itemHeight = item.offsetHeight;
            const itemWidth = item.offsetWidth;

            item.style.setProperty('--item-height', itemHeight + "px");
            item.style.setProperty('--item-width', itemWidth + "px");
        })

	},

};

module.exports = cssVarsDynamic;
