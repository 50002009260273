/**
 * @function
 * @name outerHeight Get height with margin top and bottom
 */
export function outerHeight(el) {
    let height = el.offsetHeight;
    const style = getComputedStyle(el);
  
    height += parseInt(style.marginTop) + parseInt(style.marginBottom);
    return height;
}

/**
 * @function
 * @name exist Get true if element exist in DOM
 */
export function exist(CSSSelector) {
    const element = document.querySelector(CSSSelector);

    if (element) {
        return true
    } else { 
        return false;
    }
}

/**
 * @function
 * @name disableScroll Disable scroll on page
 */
export function disableScroll() {
    document.querySelector('body').classList.add('--hidden');
}

/**
 * @function
 * @name enableScroll Enable scroll on page
 */
export function enableScroll() {
    document.querySelector('body').classList.remove('--hidden');
}