const customFileInput = {
	ui: {},

	init: function() {
		
		const inputs = document.querySelectorAll('.js-customFileInput');

		Array.prototype.forEach.call( inputs, function( input ){
			let label	 = input.nextElementSibling;
			let labelVal = label.innerHTML;

			input.addEventListener( 'change', function(e){

				const selectedFile = input.files[0]; // Récupérez le premier fichier sélectionné

				if (selectedFile) {
					const fileName = selectedFile.name; // Obtenez le nom complet du fichier
					const fileParts = fileName.split('.'); // Divisez le nom en deux parties (nom de base et extension)

					label.querySelector( 'span' ).innerHTML = fileName;
				}

			});
		});

	},

};

module.exports = customFileInput;
