const navSecondary = {
	ui: {},

	init: function() {
		this.dropdown();
	},

    dropdown: function() {

        const btn = $('.js-nav-dropdown-btn');

        if (!btn) return;

        btn.on('click', function(e){
            e.preventDefault();

            $(this).closest('.js-nav-dropdown').find('.js-nav-dropdown-content').slideToggle(200)
            $(this).toggleClass('--active');
        })

    }

};

module.exports = navSecondary;
